import React, { useState, useEffect } from 'react';
import Field from '../../Field/Field';
import CTAButton from '../../CTAButton/CTAButton';
import SweetAlert from 'react-bootstrap-sweetalert';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { useRouter } from 'next/router';
import { ToastContainer, toast } from 'react-toastify';
import FlashMessage from "../../FlashMessage/Message";
import cookies from 'next-cookies';
import './AccessCode.scss';
import Close from '../../SVG/Close';
import { MAIN_CONFIG } from '../../../../config/main';
import { handleKeyDown } from '../../../Utility/ApplicationFormUtility';


export default ({
    token,
    scholarshipId,
    setLoading,
    redirectURL = "",
    applicationId,
    submitClicked = false,
    setAccessCodeTrue = () => { },
    setModalState,
    editUrl = "",
    submitValidationPopup = false,
    readyToApply = false,
    redirectFunction = () => { },
    isRedirectSSO = false,
    redirectFuc = () => { },
}) => {

    const router = useRouter()

    const API = new KaleidoscopeAPI({});

    const [accessCode, setAccessCode] = useState("")
    let userContext = cookies('context').context

    const onClose = (e) => {
        setModalState({
            visible: false,
            className: '',
            content: []
        });
    }

    const handleAccessCode = async (e) => {
        e.preventDefault()
        if (!submitClicked) {
            const response = await API.checkAccessCode({ 'token': cookies('context').token, scholarshipId: scholarshipId ? scholarshipId : router.query.scholarshipId, code: accessCode, applicationId: applicationId })

            if (response.success) {
                let arr = localStorage.getItem('hasAccessCode') ? JSON.parse(localStorage.getItem('hasAccessCode')) : []
                if (arr.includes(applicationId) === false) {
                    arr.push(applicationId)
                    localStorage.setItem('hasAccessCode', JSON.stringify(arr))
                    localStorage.setItem('accessCode', accessCode)
                }
                toast.success(<FlashMessage message={response.message} />)

                {
                    if (readyToApply) {
                        redirectFunction()
                    }else if(isRedirectSSO){
                        redirectFuc()
                    }else if (redirectURL?.length > 0) {
                        setLoading(true)
                        setTimeout(() => {
                            // setLoading(false)
                            router.push(`${redirectURL}`, undefined, { shallow: true })
                        }, 4000);
                    }
                }
                if (submitClicked) {
                    setAccessCodeTrue({ isTrue: true, accessCode: accessCode })
                }

                if (submitValidationPopup) {
                    setTimeout(() => {
                        window.location.href = editUrl
                    }, 4000);
                }
                setModalState({ visible: false });

            } else {
                toast.error(<FlashMessage message={response.message} />);
                setTimeout(() => {
                    setLoading(false)
                    if(userContext === 'APPLICANT'){
                        router.push(`/applicant`, undefined, { shallow: true })
                    }else{
                        router.push(`/`, undefined, { shallow: true })
                    }
                }, 3000);

            }
        } else {
            setAccessCodeTrue({ isTrue: true, accessCode: accessCode })
        }
    }

    return (
        <div id='task-modal-content' className='membership-enter-level-root'>
            <div className="task-head_container membership-enter-level-modal">
                <div className="H1DesktopGreen event-head">
                    <div className="membership-enter-level-modal-title">Access Code</div>
                </div>
                <div>
                <button className='event-head H6DesktopGrey cancel-icon close-btn' onClick={onClose} onKeyDown={(e)=>handleKeyDown(e,()=>onClose(e))}><Close color={MAIN_CONFIG.COLORS.fillGrey} ariaLabel='Close' ariaLabelDescription='' /></button>
                </div>
            </div>

            <div className="user-task-add__form">
                <form onSubmit={(e) => handleAccessCode(e)}>

                    <div className="application-section__question" >
                        <label className="application-section__label">
                        </label>
                        <Field
                            id={'question_id'}
                            className="application-section__textarea"
                            placeholder='Please Enter Access Code.'
                            type={'text'}
                            value={accessCode}
                            required={true}
                            handleChange={(value) => setAccessCode(value)}
                        />
                    </div>

                    <div className="applicant-task__submit">
                        <CTAButton id='task-page__cta__cancel' type='inverse' outline onClick={onClose} buttonType="button">
                            Cancel
                        </CTAButton>

                        <CTAButton id='task-page__cta' type='tertiary'>
                            Submit
                        </CTAButton>
                    </div>

                </form>
            </div>
        </div>
    )
}